import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  userId,
  token,
  url,
  eligibleGalaOnly,
  physical,
  galaOnly,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedGalaOnly, setSelectedGalaOnly] = useState(null);
  const [pax, setPax] = useState(0);
  const [wheelChair, setWheelChair] = useState(false);

  const today = new Date();
  const march = new Date(today.getFullYear(), 2, 1); // Months are 0-indexed in JavaScript

  let sstRate = today < march ? 6 : 8;

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedGalaOnly]}
        />
        <input type="hidden" name="pax_coming_with" value={pax} />
        <input type="hidden" name="wheelchair" value={wheelChair} />

        <ProductCard
          cardTitle={"IFSBH 2024 Conference Ticket"}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
            } else {
            }
          }}
          handleRadio={() => {}}
        />

        {eligibleGalaOnly && (
          <ProductCard
            cardTitle={"IFSBH 2024 Gala Dinner Ticket"}
            products={galaOnly}
            selectedState={selectedGalaOnly}
            setSelectedState={setSelectedGalaOnly}
            activated
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedGalaOnly(null);
              }
            }}
            handleRadio={() => {}}
          />
        )}

        {selectedGalaOnly && (
          <div className="text-center">
            <h5 className="pt-4">Are you a wheelchair user?</h5>
            <div className="d-flex justify-content-center">
              <select
                className="form-control"
                style={{ maxWidth: "150px" }}
                value={wheelChair}
                onChange={(e) => setWheelChair(e.target.value)}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            <h5 className="pt-4">Are you coming with anyone else?</h5>

            <div className="d-flex justify-content-center">
              <select
                className="form-control"
                style={{ maxWidth: "150px" }}
                value={pax}
                onChange={(e) => setPax(e.target.value)}
              >
                <option value="0">No one else</option>
                {Array.from({ length: 10 }, (_, index) => (
                  <option key={index} value={index}>
                    {index}
                  </option>
                ))}
              </select>
            </div>

            {/* if selected more than 0, show the field for the pax names */}
            {pax > 0 && (
              <div className="d-flex flex-column align-items-center">
                <h5 className="pt-4">Whom are you coming with?</h5>
                {Array.from({ length: pax }, (_, index) => (
                  <div
                    key={index}
                    className="comingWith"
                    style={{ width: "400px" }}
                  >
                    <h6 className="pt-1">Accompanying Person {index + 1}</h6>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        id={`comingWith${index + 1}`}
                        name={`comingWith${index + 1}`}
                        required
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur {sstRate}% SST
          </strong>
        )}
        <button
          type="submit"
          className="theme-btn d-block mx-auto mt-4"
          disabled={
            galaOnly
              ? selected === null && selectedGalaOnly === null
              : selected === null
          }
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
