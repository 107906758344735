import React from 'react'

const CommunitySummary = ({
  currentUserName,
  currentUserEmail,
  ownerIdx,
  participants,
  physical,
  child
}) => {
  // console.log(participants)
  return (
    <div id="communitySummary">
      <div className='summaryTable'>
        <h4>Order Summary</h4>
        <table className='table'>
          <tbody>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Coming As</th>
              <th>Ticket Type</th>
              <th>Ticket Price</th>
            </tr>
            {participants.map(guest => {
              const selected = physical.concat(child).find(pro => pro.id == guest.product_ids)
              return (
                <tr key={`table-${guest.id}`}>
                  <td>{guest.id}</td>
                  <td>{ownerIdx === guest.id ? currentUserName : guest.full_name}</td>
                  <td>{ownerIdx === guest.id ? currentUserEmail : guest.email}</td>
                  <td>{guest.coming_as}</td>
                  <td>{selected.productName}</td>
                  <td>{selected.price}</td>
                </tr>
              )
            })}
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Total (8% SST Inclusive)</th>
              <th>
                {physical.concat(child)[0].currency === "myr" ? 'RM': 'EUR'}
                {participants.map(per => physical.concat(child).find(pro => pro.id == per.product_ids).amount).reduce((accumulator, currentValue) => accumulator + currentValue,0)/100}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    
    </div>
  )
}
export default CommunitySummary